<template>
  <div>
    <v-card class="pt-2" elevation="0">
      <app-accounting-entry-filter-2
        @filterTable="applySearch($event)"
        @resetFilter="resetFilterObject"
      ></app-accounting-entry-filter-2>
    </v-card>
  </div>
</template>

<script>
import spinner from "../../../../components/base/spinner.vue";
import accountingEntryFilter2 from "./accountingEntryFilter2.vue";
import axios from "axios";
import pagination from "../../../../components/base/pagination.vue";
import deleteEntry from "./deleteAccountingEntry.vue";
export default {
  components: {
    appSpinner: spinner,
    appDeleteEntry: deleteEntry,
    appAccountingEntryFilter2: accountingEntryFilter2,
    appPagination: pagination,
  },
  data() {
    return {
      accountingEntries: [],
      totalTablePages: null,
      loading: false,
      filterObject: {
        user: null,
        id: null,
        debit: null,
        credit: null,
        desc: "",
        costCenter: null,
        from_date: "",
        to_date: "",
        account: null,
      },
    };
  },
  methods: {
    resetFilterObject() {
      this.filterObject.user = null;
      this.filterObject.id = null;
      this.filterObject.debit = null;
      this.filterObject.credit = null;
      this.filterObject.desc = "";
      this.filterObject.costCenter = null;
      this.filterObject.from_date = "";
      this.filterObject.to_date = "";
      this.filterObject.account = null;
    },
    applySearch(filterObject) {
      this.filterObject = filterObject;
      this.fetchAccountingEntry();
    },
    applyPagination(paginationValue) {
      this.filterObject.page = paginationValue;
      this.fetchAccountingEntry();
    },
    async fetchAccountingEntry() {
      try {
        this.loading = true;
        if (!this.filterObject.user) delete this.filterObject.user;
        if (!this.filterObject.id) delete this.filterObject.id;
        if (!this.filterObject.debit) delete this.filterObject.debit;
        if (!this.filterObject.credit) delete this.filterObject.credit;
        if (!this.filterObject.desc) delete this.filterObject.desc;
        if (!this.filterObject.costCenter) delete this.filterObject.costCenter;
        if (!this.filterObject.from_date) delete this.filterObject.from_date;
        if (!this.filterObject.to_date) delete this.filterObject.to_date;

        this.filterObject.perPage = 10;
        const entries = await axios.get("/accounting/entry", {
          params: this.filterObject,
        });
        this.accountingEntries = entries.data.data;
        this.totalTablePages = entries.data.meta.total;
        this.filterObject.page = entries.data.meta.current_page;
        this.accountingEntries.forEach((item) => {
          item.uid = this.$uuid.v1();
          console.log(item.uid);
        });
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    if (this.returnAbility("entry:delete")) {
      this.displayDeleteIcon = true;
    }
    this.fetchAccountingEntry();
  },
};
</script>

<style scoped>
.pt-2 {
  top: 90px;
  right: 300px;
}
</style>