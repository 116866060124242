<template>
  <div style="position: absolute; left: 45%;">
    <v-row>
      <v-col md="3">
        <v-autocomplete hide-details v-model="year" outlined dense :items="years.educational_years" :label="$t('year')"
          item-text="name" item-value="value"></v-autocomplete>
      </v-col>

      <v-col md="3">
        <v-autocomplete v-model="filterData.user" :label="$t('account name')" :items="userItems" item-text="name"
          item-value="id" :loading="userItemsLoading" no-filter color="#757575"
          @keypress="fetchUsersItemsAutoComplete"></v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-btn color="success" class="login__btn" :disabled="!year || !filterData.user" @click="rotaion">{{ $t('rotation
        now') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import debounce from "../../../../helpers/debounce";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  data() {
    return {
      year: null,
      years: [],
      fromDialog: false,
      toDialog: false,
      filterData: {
        user: null,
        id: null,
        debit: null,
        credit: null,
        desc: "",
        costCenter: null,
        from_date: "",
        to_date: "",
        page: 1,
        account: null,
      },
      menu: false,
      userItems: [],
      userItemsLoading: false,
      accountItems: [],
      accountItemsLoading: false,
      costCenterItems: [],
      costCenterItemsLoading: false,
      validDate: false,
    };
  },
  computed: {
    disableCredit() {
      return !!this.filterData.debit;
    },
    disableDebit() {
      return !!this.filterData.credit;
    },
  },
  methods: {
    resetFilter() {
      this.filterData.user = null;
      this.filterData.id = null;
      this.filterData.debit = null;
      this.filterData.credit = null;
      this.filterData.desc = "";
      this.filterData.costCenter = null;
      this.filterData.from_date = "";
      this.filterData.to_date = "";
      this.filterData.account = null;
      this.$emit("resetFilter");
    },
    checkValidation() {
      if (this.filterData.from_date && this.filterData.to_date) {
        if (
          new Date(this.filterData.from_date) >
          new Date(this.filterData.to_date)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    filterTable() {
      if (this.checkValidation()) {
        this.$emit("filterTable", this.filterData);
      } else {
        Notifications(
          "التاريخ المدخل خاطئ",
          { timeout: 4000, rtl: true },
          "info"
        );
      }
    },
    async fetchUsersItemsAutoComplete(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.userItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.userItems = searchResult.data.data;
          console.log(searchResult.data.data);
        } catch (err) {
          console.log("err", err);
        } finally {
          this.userItemsLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteAccount(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.accountItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/account/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );

          this.accountItems = searchResult.data.data;
          console.log(accountItems);
        } catch (err) {
          console.log("err", err);
        } finally {
          this.accountItemsLoading = false;
        }
      }, 500)();
    },
    async fetchAutoCompleteCostCenter(searchQuery) {
      debounce(async () => {
        let searchValue = searchQuery.target.value;
        this.costCenterItemsLoading = true;
        if (!searchValue) return;
        try {
          const searchResult = await axios.get(
            "/accounting/cost-center/auto-complete",
            {
              params: {
                query: searchValue,
              },
            }
          );
          this.costCenterItems = searchResult.data.data;
        } catch (err) {
          console.log("err", err);
        } finally {
          this.costCenterItemsLoading = false;
        }
      }, 500)();
    },
    rotaion() {
      console.log("the Year is ", this.year);
      console.log("the id is ", this.filterData.user);
      //  axios.post('api url',{
      //              //set here the value you want to send it
      // }).then(response =>{
      //   if(response.status==200){
      //     this.$Notifications(
      //     "تم التسجيل بنجاح",
      //     { rtl: true, timeout: 2000 },
      //     "succes"
      //   );
      //   }error=>{
      //      this.$Notifications(
      //     "حدث خطأ ما ",
      //     { rtl: true, timeout: 2000 },
      //     "error"
      //   );
      //   }
      // })
      this.year = null;
      this.filterData.user = null;
    },
  },
  mounted() {
    axios
      .get(`/educational-year`)
      .then((response) => (this.years = response.data));
  },
};
</script>

<style scoped>
.login__btn {
  /* right: 120px; */
}
</style>